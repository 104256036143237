import React, { Children, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OAUTH_URL, CLIENT_ID, REDIRECT_URI, SCOPE, RESPONSE_TYPE } from "./config";
import { SortableTree } from "../tree/SortableTree";
import { Button } from "@mui/material";

const validateUrl = (url, validOrigins) => {
	try {
		const parsedUrl = new URL(url);
		if (!validOrigins.includes(parsedUrl.origin)) {
			throw new Error("Invalid redirect URL");
		}
		return url;
	} catch (error) {
		console.error("URL validation error:", error);
		return null;
	}
};
const Wrapper = ({ children }) => (
	<div
		style={{
			maxWidth: 200,
			padding: 10,
			margin: '0 auto',
			marginTop: '10%',
		}}
	>
		{children}
	</div>
);

const Navbar = ({ isAuthenticated }) => {
	const redirectUrl = validateUrl(`${OAUTH_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&response_type=${RESPONSE_TYPE}`, ["https://threads.net"]);

	// const handleClick = () => {
	// 	try {
	// 		window.location.assign(redirectUrl);
	// 	} catch (error) {
	// 		console.error("Redirection failed:", error);
	// 		// Optionally, provide user feedback or fallback logic here
	// 	}
	// };
	if (!isAuthenticated)
		return
	const [items, setItems] = useState([]);
	useEffect(() => {
	}, [items]);
	return (
		<div className="navbar">
			<button className="primary-button" onClick={
				() => {
					console.log("addItem");
					setItems([...items, { id: Date.now(), children: [], isDir: false }]);
					console.log(items);
				}
			}>
				addItem
			</button>
			{/* <section className="navbar-section"> */}
			{/* <Link to="/" className="btn btn-link">
					Threads
				</Link> */}
			{/* <Link to="/threads-v2" className="btn btn-link">
					Threads
				</Link>
				<Link to="/profile" className="btn btn-link">
					프로필
				</Link> */}
			{/* <Link to="/insight" className="btn btn-link">
					자동생성
				</Link> */}
			<Wrapper>
				{/* <SortableTree defaultItems={items} collapsible indicator removable /> */}
				<SortableTree collapsible indicator removable />
			</Wrapper>

			{/* </section> */}
			{/* <section className="navbar-section">
				<Link onClick={handleClick} className="btn btn-primary">
					로그인
				</Link>
			</section> */}
		</div>
	);
};

export default Navbar;
