// src/App.jsx
import "./App.css"; // CSS 파일 추가
import { AuthProvider } from "./contexts/AuthContext";
import { ErrorProvider } from "./contexts/ErrorContext";
import { SuccessProvider } from "./contexts/SuccessContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import { ContainerProvider } from "./contexts/ContainerContext";
import AppContent from "./components/AppContent";
function App() {
	return (
		<ErrorProvider>
			<SuccessProvider>
				<AuthProvider>
					<LoadingProvider>
						<ContainerProvider>
							<AppContent />
						</ContainerProvider>
					</LoadingProvider>
				</AuthProvider>
			</SuccessProvider>
		</ErrorProvider>
	);
}

export default App;
