import { createActor, assign, fromPromise, createMachine } from "xstate";

interface AuthContext {
    isAuthenticated: boolean;
}

type AuthEvent =
    | { type: 'LOGIN' }
    | { type: 'LOGOUT' }
    | { type: 'LOGIN_SUCCESS' }
    | { type: 'LOGIN_FAILURE', error: string }
    | { type: 'CHECK_AUTH' }


export const authMachine = createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgOgJYQBswBiAYQAkBRUgaQH0BBAVQBVyBtABgF1FQAHAPaxc6XIIB2fEAA9EARgBMAVmwAWAJxaNADkUBmDWoBs+zvIA0IAJ4LOx7Mc1b5xjYp375a-QF9fVmhY2ADGmGAhANa4ElDEEJJgeBIAboKRSUE4YRHRsQgxaSHIYpJc3OXSQiKlUkiyiMry+tjKAOw68k36xm46-Va2CPo6nK2eZj56nMpq-oEY2eFRMXFgAE7rguvY-AQlAGbbALbYWaHLeVAFqYLFteWV9dWi4nWgcgjdrR1dzb26AY2RCKTgtNrKThQmb6fQqDTyNrzEDnLJgCRie6QYgAGQA8gBxPGsJ4CYSvSTST46ZSKbA6DT2YzyFlmWn6QaIWFjTgTRGgxRqNruOYBFGLM6LdGYkrYijUejMNikkAvWpUxoGbCC7QstqKJzGTnDMzYXmw-mcQXCwXI86oCRojG4LEQXGEgCSADkVWq3hqEIyNNg4Z02iZab9FMaRmNlBNOFNQbM7RKHU6Zeg5VRaIxWBweFVyer6tTafTGb0Wc0ZgZjZ1WtDE2pvLy2sYdP4xRJBBA4NIskWav7S4gALRG4EICep4L4IhDinvBoINTRqfyThtbDeLQd-RtZvGRSKWdLXKrRclj4ghE7nQQxRGYxWvX1lrGSFQpw6IVKZTGGekpYNKLqyhAV4jjeCAdjo2AaAetIGAhhhCjGJ7wdoCLKAhvSCoBYr2o6UrOq6kGUqOCCbh4PydO4sKxpwGjGvIDLatobRKIYtKbp2XZAA */
    id: 'auth',
    initial: 'idle',
    context: {
        isAuthenticated: false,
    } as AuthContext,
    states: {
        idle: {
            on: {
                CHECK_AUTH: 'checking'
            }
        },
        checking: {
            invoke: {
                src: 'checkAuth',
                onDone: {
                    target: 'authenticated',
                },
                onError: 'unauthenticated'
            }
        },
        authenticated: {
            entry: () => localStorage.setItem('isAuthenticated', 'true'),
            on: {
                LOGOUT: 'unauthenticated',
                CHECK_AUTH: 'checking'
            }
        },
        unauthenticated: {
            entry: () => localStorage.setItem('isAuthenticated', 'false'),
            on: {
                LOGIN: 'authenticated',
                CHECK_AUTH: 'checking'
            }
        }
    }

}, {
    actions: {
    },
    actors: {
        checkAuth: fromPromise(async () => {
            try {
                const response = await fetch('https://api.so-eum.link/me', {
                    credentials: 'include',
                });
                if (!response.ok) throw new Error("인증 실패")
                return true
            } catch (error: any) {
                throw new Error(error.message || "오류가 발생했습니다.");
            }
        })
    }
})