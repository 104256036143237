import { createActorContext } from "@xstate/react";
import { authMachine } from "../machines/authMachine";

const AuthContext = createActorContext(authMachine);

export const AuthProvider = ({ children }) => {
    return (
        <AuthContext.Provider>
            {children}
        </AuthContext.Provider>
    );
}

export const useAuthRef = AuthContext.useActorRef;