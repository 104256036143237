import { useEffect } from "react";
import { useSelector } from '@xstate/react';
import { ControlPanelV2 } from "../components/ControlPanelV2";
import { useContainerRef } from "../contexts/ContainerContext";

const Container = () => {
    const containerMachineRef = useContainerRef();
    const send = containerMachineRef.send;
    const state = useSelector(containerMachineRef, state => state);

    useEffect(() => {
        if (state.matches('idle')) {
            console.log("call fetch")
            send({ type: "FETCH", loading: true });
        }
    }, [send]);

    let containerBody;
    switch (state.value) {
        case 'idle':
            containerBody = (
                <div className="section">
                </div>
            )
            break;
        case 'loading':
            break;
        case 'error':
            break;
        case 'loaded':
    }

    return (
        <div className="container">
            {state.matches('loaded') && <ControlPanelV2
                selectedThreads={state.context.selectedThreads}
            />}
            <div className="section">
                <div className="threads-grid">
                    {state.context.threads.map((thread) => (
                        <div
                            key={thread.id}
                            className={`thread-card ${state.context.selectedThreads.includes(thread) ? "selected" : ""}`}
                            onClick={() => send({ type: "TOGGLE_SELECT", selectedThread: thread })}
                        >
                            <div className="card-body">
                                <p>{thread.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Container;