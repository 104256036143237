
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "../pages/Main";
import Navbar from "./Navbar";
import Profile from "../pages/Profile";
import Container from "../pages/Container";
import { useSelector } from "@xstate/react";
import { useAuthRef } from "../contexts/AuthContext";

const AppContent = () => {
    const authMachineRef = useAuthRef();
    const isAuthenticated = useSelector(authMachineRef, (state) => {
        return state.matches('authenticated') || state.context.isAuthenticated;
    });

    return (
        <Router>
            <Navbar isAuthenticated={isAuthenticated} />
            <Routes>
                <Route path="/" element={isAuthenticated ? <Container /> : <Main />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/threads-v2" element={<Container />} />
            </Routes>
        </Router>
    );
}

export default AppContent;