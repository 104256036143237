import { createMachine, assign } from 'xstate';

export interface ControlPanelContext {
    keywords: string;
    generatedText: string;
    charCount: number;
    error: string | null;
    hasFormat: boolean;
    maxCharCount: number;
    time: number;
    scheduledTime: number;
    hasTimePicker: boolean;
}

type ControlPanelEvent =
    | { type: 'SET_KEYWORDS'; keywords: string }
    | { type: 'UPDATE_TIME'; time: number }
    | { type: 'GENERATE' }
    | { type: 'UPDATE_EDITED_TEXT'; text: string }
    | { type: 'UPLOAD' }
    | { type: 'TOGGLE_FORMAT' }
    | { type: 'TOGGLE_TIME_PICKER' }
    | { type: 'SET_MAX_CHAR_COUNT'; maxCharCount: number };

const initialContext: ControlPanelContext = {
    keywords: '',
    generatedText: '',
    charCount: 0,
    error: null,
    hasFormat: false,
    hasTimePicker: false,
    maxCharCount: 500,
    // time: Math.floor(Date.now() / 1000),
    time: Math.floor(Date.now() / 1000),
    scheduledTime: 0,
}

export const controlPanelMachine = createMachine({
    id: 'controlPanel',
    initial: 'idle',
    context: initialContext,
    on: {
        RESET: {
            target: '.idle',
        }
    },
    states: {
        idle: {
            entry: assign(initialContext),
            on: {
                GENERATE: 'generating',
                SET_KEYWORDS: {
                    actions: assign({
                        keywords: ({ context, event }) => event.keywords
                    })
                },
                TOGGLE_FORMAT: {
                    actions: assign({
                        hasFormat: ({ context, event }) => !context.hasFormat
                    })
                },
                SET_MAX_CHAR_COUNT: {
                    actions: assign({
                        maxCharCount: ({ context, event }) => event.maxCharCount
                    })
                }
            }
        },
        generating: {
            invoke: {
                src: 'generateText',
                input: ({ context }) => ({ hasFormat: context.hasFormat, maxCharCount: context.maxCharCount }),
                onDone: {
                    target: 'generated',
                    actions: [assign({
                        generatedText: ({ context, event }) => {
                            console.log('Generated text:', event.output);
                            return event.output.text
                        },
                        charCount: ({ context, event }) => event.output.text.length,
                    }), "destroyLoading"]
                },
                onError: {
                    target: 'idle',
                    actions: assign({
                        error: ({ context, event }) => null
                    })
                }
            }
        },
        generated: {
            on: {
                UPLOAD: 'uploading',
                UPDATE_EDITED_TEXT: {
                    actions: assign({
                        generatedText: ({ context, event }) => event.text,
                        charCount: ({ context, event }) => event.text.length
                    })
                },
                UPDATE_TIME: {
                    actions: assign({
                        time: ({ context, event }) => event.time
                    })
                },
                TOGGLE_TIME_PICKER: {
                    actions: assign({
                        hasTimePicker: ({ context, event }) => !context.hasTimePicker
                    })
                }

            }
        },
        uploading: {
            invoke: {
                src: 'uploadText',
                onDone: {
                    target: 'idle',
                    actions: 'notifySuccess'
                },
                onError: {
                    target: 'generated',
                    actions: assign({
                        error: ({ context, event }) => null
                    })
                }
            }
        }
    }
}, {
    actions: {
    },
});