import { createActorContext } from "@xstate/react";
import { containerMachine } from "../machines/containerMachine";
import { fromPromise } from "xstate";
import { useSuccess } from "../contexts/SuccessContext";
import { useError } from "../contexts/ErrorContext";
import { useLoading } from "../contexts/LoadingContext";

const ContainerContext = createActorContext(containerMachine);

export const ContainerProvider = ({ children }) => {
    const { handleSuccess } = useSuccess();
    const { handleError } = useError();
    const { setLoading } = useLoading(false);

    return (
        <ContainerContext.Provider logic={
            containerMachine.provide({
                actions: {
                    notifySuccess: () => handleSuccess("스레드를 성공적으로 불러왔습니다."),
                    notifyError: (context) => handleError(context.context.error),
                    setLoading: (context) => setLoading(context.event.loading),
                },
                actors: {
                    fetchThreads: fromPromise(async () => {
                        try {
                            console.log("call fetchThreads")
                            const apiUrl = import.meta.env.VITE_API_URL || "https://api.so-eum.link/posts";
                            const response = await fetch(apiUrl, {
                                method: "GET",
                                credentials: "include",
                            });
                            if (!response.ok) {
                                throw new Error("네트워크 응답에 문제가 있습니다.");
                            }
                            const data = await response.json();
                            // 불필요한 빈 문자열 제거
                            return data.filter((thread) => thread.text && thread.text.trim() !== "");
                        } catch (error) {
                            throw new Error(error.message || "데이터를 불러오는 중 오류가 발생했습니다.");
                        }
                    }),
                },
            })
        }>
            {children}
        </ContainerContext.Provider>
    );
}

export const useContainerRef = ContainerContext.useActorRef;